<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center page"
    style="color: #626262"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="card-bg page-card">
          <div v-if="!isLoading && !isSuccess">
            <div style="margin-bottom: 25px">
              <img
                src="@/application/assets/images/logo/dnaskills.png"
                style="height: 128px; margin: auto"
              />
            </div>
            <div class="header-text">
              <h3>{{ $l.get("password-reset.header") }}</h3>
            </div>
            <!-- <div class="centerx"> -->
            <form @submit.prevent="onSubmit">
              <vs-input
                v-model="formPassword"
                :danger="isPasswordInvalid"
                :danger-text="$l.get('errors.input-required', 'capitalize')"
                :label-placeholder="$l.get('dictionary.password', 'capitalize')"
                type="password"
              />
              <vs-input
                v-model="formPasswordConfirm"
                :danger="isConfirmPasswordInvalid"
                :danger-text="
                  $l.get('errors.input-invalid-password-confirm', 'capitalize')
                "
                :label-placeholder="
                  $l.get('dictionary.password-confirm', 'capitalize')
                "
                type="password"
              />

              <div style="margin-top: 25px; margin-bottom: 25px">
                <vs-button color="primary" type="filled" @click="onSubmit">{{
                  $l.get("dictionary.submit")
                }}</vs-button>
              </div>

              <input
                type="submit"
                style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                tabindex="-1"
              />
            </form>
          </div>
          <div
            class="animated fadeIn"
            style="text-align: center !important"
            v-if="isLoading && !isSuccess"
          >
            <Loader :label="$l.get('password-reset.loader', 'capitalize')" />
          </div>
          <div
            class="animated fadeIn"
            style="text-align: center !important"
            v-if="!isLoading && isSuccess"
          >
            <feather-icon icon="KeyIcon" style="margin-bottom: 10px" />
            <p>{{ $l.get("password-reset.success", "capitalize") }}</p>
            <vs-button
              color="primary"
              type="flat"
              style="margin-top: 10px"
              @click="onLoginButtonClick"
              >{{ $l.get("welcome.login-button") }}</vs-button
            >
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import SelectLoginInteractor from "@/business/password-reset/select-login";
import InitPasswordResetInteractor from "@/business/password-reset/init-password-reset";
import SubmitPasswordResetInteractor from "@/business/password-reset/submit-password-reset";
import PasswordResetScreenController from "@/adapters/controllers/screen-password-reset";
import { UserPasswordReset } from "@/domain/users/models";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "password-reset",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        selectLogin: null,
        initPasswordReset: null,
        submitPasswordReset: null
      },
      formPassword: "",
      formPasswordConfirm: "",
      isPasswordInvalid: false,
      isConfirmPasswordInvalid: false,
      isLoading: false,
      isSuccess: false,
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(PasswordResetScreenController);

    //{ INTERACTORS
    this.interactors.submitPasswordReset = this.$injector.get(
      SubmitPasswordResetInteractor
    );
    this.interactors.initPasswordReset = this.$injector.get(
      InitPasswordResetInteractor
    );
    this.interactors.selectLogin = this.$injector.get(SelectLoginInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.setAsPasswordInvalid = this.setAsPasswordInvalid;
    this.controller.setAsConfirmPasswordInvalid = this.setAsConfirmPasswordInvalid;
    this.controller.clearFormErrors = this.clearFormErrors;
    this.controller.setAsSuccess = this.setAsSuccess;
  },
  mounted() {},
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    setAsPasswordInvalid() {
      this.isPasswordInvalid = true;
    },
    setAsConfirmPasswordInvalid() {
      this.isConfirmPasswordInvalid = true;
    },
    clearFormErrors() {
      this.isPasswordInvalid = false;
      this.isConfirmPasswordInvalid = false;
    },
    setAsSuccess() {
      this.isSuccess = true;
    },
    onLoginButtonClick() {
      this.interactors.selectLogin.handle();
    },
    onSubmit() {
      const code = this.$route.params.code;
      this.interactors.submitPasswordReset.handle(
        new UserPasswordReset(code, this.formPassword, this.formPasswordConfirm)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-bg .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}
.card-bg .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}
.inputs .vs-input {
  margin: 15px;
}
</style>
